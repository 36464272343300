@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.heading {
  @apply text-left text-4xl font-bold text-dark;
}

.subheading {
  @apply text-left text-xl font-semibold leading-snug;
}

.caption {
  @apply text-base font-bold leading-normal;
}

.body-text {
  @apply text-base font-normal leading-relaxed;
}

.footnote {
  @apply text-sm font-normal leading-relaxed;
}

textarea:disabled {
  color: grey; /* 灰色文字 */
}
